$iconSizes: (
    xs: (
        desktop: 12px,
        tablet: 12px,
        mobile: 12px
    ),
    sm: (
        desktop: 1.24rem,
        tablet: 1.24rem,
        mobile: 1.24rem
    ),
    base: (
        desktop: 1.5rem,
        tablet: 1.5rem,
        mobile: 1.5rem
    ),
    md: (
        desktop: 1.6rem,
        tablet: 1.6rem,
        mobile: 1.6rem
    ),
    lg: (
        desktop: 1.75rem,
        tablet: 1.75rem,
        mobile: 1.75rem
    ),
    xl: (
        desktop: 2rem,
        tablet: 2rem,
        mobile: 2rem
    ),
    2xl: (
        desktop: 4rem,
        tablet: 4rem,
        mobile: 4rem
    ),
    3xl: (
        desktop: 5rem,
        tablet: 5rem,
        mobile: 5rem
    ),
    4xl: (
        desktop: 6.25rem,
        tablet: 6.25rem,
        mobile: 6.25rem
    )
);

@function iconSize($size, $platform: 'desktop') {
    @return map-get(map_get($iconSizes, $size), $platform);
}

$spacings: (
    tn: 8px,
    xs: 12px,
    sm: 16px,
    base: 20px,
    md: 24px,
    lg: 32px,
    xl: 40px,
    hg: 56px,
    gt: 88px
);

@function spacing($size: 'base') {
    @return map-get($spacings, $size);
}

$breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    hg: 1560px
);

:root {
    --side-padding-lg: #{spacing(hg)};
    --side-padding-md: #{spacing(md)};
    --side-padding-sm: #{spacing(base)};
}

$screen-lg: 1200px;
$screen-md: 1024px;
$screen-sm: 768px;

$directions: (
    top, left, right, bottom
);

$site-frame-height-base: 100%;
$bottom-frame-height-base: 400px;

$border-width-thin: 1px;
$border-width-base: 1px;
$border-width-bold: 2px;
$border-width-fat: 3px;

$border-radius-small: 2px;
$border-radius-base: 5px;
$border-radius-large: 10px;
$border-radius-extra-large: 20px;

$border-color-base: color(grey, 500);
$border-color-darker: rgba(color(black), .2);

$box-shadow-base: 0 1px 2px 0 rgba(color(black), .15);
$box-shadow-medium: 0 10px 20px 0 rgba(color(black), .2);
$box-shadow-heavy: 0 4px 32px 0 rgba(color(black), 0.05);
$box-shadow-large: 0 8px 64px 0 rgba(color(black), 0.1);
$box-shadow-huge: 0 8px 16px 0 rgba(color(black), .2);

$transition-base: 0.4s all ease-in-out;
$transition-fast: 0.2s all ease-in-out;

:root {
    --base-transition-timing: 400ms;
}

html {
    scroll-behavior: smooth;
}

html, body {
    background-color: var(--background-color);
    color: var(--tertiary-color);
}

@media only screen and (max-width: $screen-sm) {
}
