.badge {
    display: inline-block;
    padding: 2px 4px;
    min-width: 18px;
    height: 18px;
    line-height: 1;
    font-size: $font-size-extra-small;
    font-weight: $font-weight-semi-bold;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 18px;
    border: $border-width-base solid color(grey, 200);
    color: color(grey, 300);
    background-color: color(white);
    transition: all 110ms;
    user-select: none;

    // Empty badges collapse automatically
    &:empty {
        display: none;
    }
}

.details__tag .badge {
    margin-right: -1px;
    margin-left: 1px;
}

.active .badge {
    border-color: rgba(color(white), .5);
    color: rgba(color(white), .5);
    background-color: transparent;
}
