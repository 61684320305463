.stars {
    display: flex;
    gap: 2px;

    .stars__item {
        @extend .icon;
        @extend .icon-mask;
        @extend .icon--xs;
        @extend .icon-StarFilled;
        color: color(yellow);
    }

    @for $i from 1 through 5 {
        &--#{$i} {
            .stars__item:nth-child(n+ #{$i + 1}) {
                @extend .icon-StarEmpty;
            }
        }
    }
}
