:root {
    --review-width: 252px;
}

.review {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    position: relative;
    z-index: 0;
    max-width: var(--review-width);
    user-select: none;

    &__stars {
        margin-bottom: spacing(tn);
    }

    &__quote {
        font-family: $font-family-typewriter;
        font-size: $font-size-small;
        line-height: $line-height-small;
        margin-bottom: 0;
    }

    &__author {
        font-family: $font-family-secondary;
    }

    &__content {
        display: block;
        margin: 0;
        padding: 16px;
        padding-bottom: 12px;
        //background-color: color(sand, 25);
        border-radius: 12px 12px 12px 0;
        position: relative;
        margin-top: auto;
        border: $border-width-base solid color(cement);
    }

    &__footer {
        background-color: color(sand, 25);
        position: relative;
        padding: 0 16px 8px;
        border-radius: 0 0 12px 12px;
        border: $border-width-base solid color(cement);
        border-top: none;
        margin-top: -1px;

        &:after, &:before {
            content: '';
            display: block;
            width: 24px;
            height: 24px;
            position: absolute;
            top: 0;
            right: -24px;
            background-color: transparent;
            border-radius: 12px;
            box-shadow: -12px -12px 0 color(sand, 25);
        }

        &:before {
            border: $border-width-base solid color(cement);
            clip-path: circle(26.5% at 25% 25%);
        }
    }
}
