$overlay-max-width: 800px;
$overlay-logo-height: 40px;

.overlay
{
    $overlay: &;

    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 12;
    opacity: 1;
    backface-visibility: hidden;
    overflow: hidden;
    transition: all 400ms ease-in-out;

    &__line {
        height: 105vh;
        position: absolute;
        opacity: .3;
    }

    &__content {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        overflow: scroll;
        padding: spacing(xs) spacing(base);
        padding-top: var(--header-height-base);
    }

    &__logo {
        display: inline-block;
        margin-bottom: spacing(sm);
        height: $overlay-logo-height;
    }

    &__background
    {
        display: block;
        width: 100%;
        height: 100%;
        background: color(white);
        position: fixed;
        top: 0;
        z-index: -1;
    }

    &__copy {
        @extend .footer__copy;
        color: color(orange);
        text-align: left;
    }

    &.hidden
    {
        display: flex !important;
        opacity: 0;
        transition: all 400ms;
        pointer-events: none;
    }

    &__close
    {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        right: spacing(base);
        top: spacing(base);
        font-size: $font-size-small;
        font-weight: $font-weight-bold;
        z-index: 1;
        color: color(black);
        cursor: pointer;

        .icon
        {
            color: inherit;
            margin-left: spacing(tn);
        }
    }

    .navigation {
        margin-top: auto;
        margin-bottom: spacing(hg);

        &__link {
            margin-bottom: spacing(xs);

            &:last-child {
                margin-bottom: 0;
            }

            > a {
                font-family: $font-family-primary;
                font-size: $font-size-medium;
                margin-left: 0;
                padding-left: 0;
            }
        }

        &--secondary {
            margin-top: auto;
            margin-bottom: 0;

            &__link {
                margin-bottom: spacing(base);
            }

            .navigation {
                &__link {
                    margin-right: spacing(sm);

                    > a {
                        font-family: $font-family-primary;
                        font-size: $font-size-small;
                        padding: 0;
                        margin: 0;
                    }
                }
            }
        }
    }
}

body {
    .overlay__backdrop {
        content: '';
        display: block !important;
        width: 100%;
        height: calc(100% - var(--header-height-base));
        position: absolute;
        top: var(--header-height-base);
        left: 0;
        z-index: 11;
        opacity: 0;
        transition: all 200ms ease-in-out;
        pointer-events: none;
    }

    &.overlay-opened {
        overflow: hidden;

        .overlay__backdrop {
            opacity: 1;
            pointer-events: initial;
            user-select: none;
            background: rgba(color(grey, 100), .9);
        }
    }
}
