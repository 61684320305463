@use 'sass:math';

$icons: (
    ArrowDown,
    Add,
    ArrowLeft,
    ArrowRight,
    ArrowUp,
    ChevronDown,
    ChevronLeft,
    ChevronLeftRight,
    ChevronRight,
    ChevronSmallDown,
    ChevronSmallLeft,
    ChevronSmallRight,
    ChevronSmallUp,
    ChevronUp,
    ChevronUpDown,
    Warning,
    Cross,
    LinkedIn,
    Mail,
    Minus,
    Search,
    Tick,
    StarFilled,
    StarEmpty
);

$menu-icon-width: 24px;
$menu-icon-height: 16px;
$menu-icon-weight: $border-width-bold;

@each $icon-key, $screen in $icons {
    @if $icon-key == base {
        @each $screen-key, $icon-value in $screen {
            @if $screen-key == desktop {
                .icon {
                    width: $icon-value;
                    height: $icon-value;
                    font-size: $icon-value;
                }
            }
        }
    } @else {
        @each $screen-key, $icon-value in $screen {
            @if $screen-key == desktop {
                .icon.icon--#{$icon-key} {
                    width: $icon-value;
                    height: $icon-value;
                    font-size: $icon-value;
                }
            }
        }
    }
}

@each $icon-key, $icon in $icons {
    .icon-#{$icon-key} {
        mask-image: url(#{$sprite}##{$icon-key}-view);
        -webkit-mask-image: url(#{$sprite}##{$icon-key}-view);
    }
}

.icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: color(orange);
    fill: currentColor;
    stroke: none;
    vertical-align: middle;
    position: relative;
    width: iconSize(base);
    height: iconSize(base);

    &--fill {
        fill: currentColor;
        stroke: none;
    }

    &--stroke {
        fill: none;
        stroke: currentColor;
    }

    &--clear {
        stroke: none;
        fill: currentColor;
    }

    &__menu {
        $menu-icon: &;

        display: inline-flex;
        align-items: center;
        flex-direction: column;
        width: $menu-icon-width;
        height: $menu-icon-height;
        color: currentColor;

        &-top,
        &-middle,
        &-bottom {
            display: block;
            width: inherit;
            height: $menu-icon-weight;
            background: currentColor;
            transform: translateY(0) translateZ(0);
            transition: all 1s cubic-bezier(0.23, 1, 0.32, 1);
            transform-origin: right;
        }

        &-middle {
            transform: translateY(math.div($menu-icon-height, 4) + math.div($menu-icon-weight, 2)) translateZ(0);
        }

        &-bottom {
            transform: translateY(math.div($menu-icon-height, 2) + $menu-icon-weight) translateZ(0);
            transform-origin: center;
        }

        &.active {
            #{$menu-icon} {
                &-top {
                    transform: rotate(-45deg) translateZ(0) translateY(.5px) translateX(0);
                }

                &-middle {
                    opacity: 0;
                }

                &-bottom {
                    transform: rotate(45deg) translateZ(0) translateY(.5px) translateX(math.div($menu-icon-height, 2) - $menu-icon-weight);
                }
            }
        }
    }

    &-mask {
        background-color: currentColor;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }
}

@media only screen and (min-width: map-get($breakpoints, md) + 1) {
    @each $icon-key, $screen in $iconSizes {
        @if $icon-key == base {
            //@each $screen-key, $icon-value in $screen {
            //    @if $screen-key == desktop {
            //        .icon {
            //            width: $icon-value;
            //            height: $icon-value;
            //            min-width: $icon-value;
            //            min-height: $icon-value;
            //        }
            //    }
            //}
        } @else {
            @each $screen-key, $icon-value in $screen {
                @if $screen-key == desktop {
                    .icon--#{$icon-key} {
                        width: $icon-value;
                        height: $icon-value;
                        min-width: $icon-value;
                        min-height: $icon-value;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: map-get($breakpoints, md)) {
    @each $icon-key, $screen in $iconSizes {
        @if $icon-key == base {
            @each $screen-key, $icon-value in $screen {
                @if $screen-key == tablet {
                    .icon {
                        width: $icon-value;
                        height: $icon-value;
                        min-width: $icon-value;
                        min-height: $icon-value;
                    }
                }
            }
        } @else {
            @each $screen-key, $icon-value in $screen {
                @if $screen-key == tablet {
                    .icon--#{$icon-key} {
                        width: $icon-value;
                        height: $icon-value;
                        min-width: $icon-value;
                        min-height: $icon-value;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: map-get($breakpoints, sm)) {
    @each $icon-key, $screen in $iconSizes {
        @if $icon-key == base {
            @each $screen-key, $icon-value in $screen {
                @if $screen-key == mobile {
                    .icon {
                        width: $icon-value;
                        height: $icon-value;
                        min-width: $icon-value;
                        min-height: $icon-value;
                    }
                }
            }
        } @else {
            @each $screen-key, $icon-value in $screen {
                @if $screen-key == mobile {
                    .icon--#{$icon-key} {
                        width: $icon-value;
                        height: $icon-value;
                        min-width: $icon-value;
                        min-height: $icon-value;
                    }
                }
            }
        }
    }
}

