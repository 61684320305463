.link {
    $root: &;

    display: inline-flex;
    align-items: center;
    color: var(--primary-color);
    gap: spacing(tn);
    cursor: pointer;
    transition: $transition-base;
    text-decoration: none;
    font-size: $font-size-base;
    font-weight: $font-weight-bold;

    &--underline {
        text-decoration: underline;
        border: none;
    }

    &--small {
        font-size: $font-size-extra-small;
    }

    &--icon {
        display: inline-flex;
        align-items: center;
        font-weight: $font-weight-regular;

        #{$root}__text {
            margin: 0 spacing(tn);
        }
    }

    &--disabled {
        pointer-events: none;
        opacity: .5;
    }

    &--block {
        display: block;
    }

    &:hover {
        text-decoration: underline;
    }
}
