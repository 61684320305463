table {
    width: 100%;
    margin-bottom: spacing(base);

    td, th {
        text-align: left;
        padding-right: spacing(md);
        padding-block: spacing(base);

        &:last-child {
            padding-right: 0;;
        }
    }
    th {
        font-size: $font-size-small;
        font-weight: $font-weight-medium;
        text-transform: uppercase;
        padding-block: 0;
    }

    tr {
        &:last-child {
            td {
                border-bottom: none;
            }
        }
    }

    tbody {
        td {
            border-bottom: $border-width-base solid color(sand, 100);
        }
    }
}

@media only screen and (max-width: map-get($breakpoints, md)) {
    table {
        td {
            width: auto !important;
        }
    }
}
