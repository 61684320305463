@use 'sass:math';

.tag {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: math.div(spacing(tn), 2);
    width: auto;
    min-height: 30px;
    border-radius: 30px;
    padding-inline: spacing(xs);
    border: $border-width-base solid var(--secondary-color);
    font-size: $font-size-small;
    font-weight: $font-weight-medium;
    color: var(--tertiary-color);
    user-select: none;

    b {
        font-weight: $font-weight-semi-bold;
        color: color(grey, 800);
    }
}
