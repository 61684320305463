:root {
    --font-size-heading-hero: #{$font-size-epic};
    --font-size-heading-1: #{$font-size-giant};
    --font-size-heading-2: #{$font-size-heavy};
    --font-size-heading-3: #{$font-size-medium};
    --font-size-heading-4: #{$font-size-base};
}

.heading {
    display: block;
    width: 100%;
    margin-bottom: spacing(base);
    //-moz-osx-font-smoothing: auto;
    //-webkit-font-smoothing: auto;
    font-weight: $font-weight-bold;
    line-height: $line-height-small;

    &--center {
        text-align: center;
    }

    &--flex {
        display: flex;
        align-items: center;
    }

    &--uppercase {
        text-transform: uppercase;
    }

    &__outline {
        color: transparent;
        font-weight: initial;
        -webkit-text-stroke: 2px var(--tertiary-color);
    }

    &--hero {
        font-size: var(--font-size-heading-hero);
        font-family: $font-family-epic;
        line-height: $line-height-extra-small;
        font-weight: initial;
        letter-spacing: $letter-spacing-extra-small-neg;
    }

    &--1 {
        font-size: var(--font-size-heading-1);
        font-family: $font-family-epic;
        line-height: $line-height-extra-small;
        font-weight: initial;
        letter-spacing: $letter-spacing-extra-small-neg;
    }

    &--2 {
        font-size: var(--font-size-heading-2);
        line-height: $line-height-small;
        margin-bottom: spacing(xl);
    }

    &--3 {
        font-size: var(--font-size-heading-3);
        color: var(--tertiary-color);
    }

    &--4 {
        font-size: var(--font-size-heading-4);
        color: var(--tertiary-color);
    }
}
@media only screen and (max-width: map-get($breakpoints, md)) {
    .heading {
        &--hero {
            --font-size-heading-hero: #{$font-size-giant};
        }

        &--1 {
            --font-size-heading-2: #{$font-size-extra-huge};
        }
    }
}

@media only screen and (max-width: map-get($breakpoints, sm)) {
    .heading {
        margin-bottom: spacing(base);

        &--flex {
            flex-direction: column;
            align-items: flex-start;
        }

        &--center-sm {
            text-align: center;
        }

        &__outline {
            -webkit-text-stroke: 1px var(--tertiary-color);
        }

        &--hero {
            --font-size-heading-hero: #{$font-size-extra-huge};
        }

        &--1 {
            --font-size-heading-1: #{$font-size-huge};
        }
    }
}
