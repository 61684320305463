$hero-image-container-height-base: 100%;
$hero-image-container-height-sm: 100%;
$hero-image-max-width: 1440px;

$hero-content-min-height-base: 200px;
$hero-content-min-height-tall: 260px;

.hero {
    $hero: &;

    display: flex;
    align-items: flex-start;
    width: 100%;
    height: auto;
    position: relative;
    z-index: 0;
    overflow: hidden;
    margin-top: spacing(xl);

    &__container {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        position: relative;
        z-index: 10;
        //padding-inline: spacing(hg);
    }

    &__content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        min-height: $hero-content-min-height-base;
        color: color(white);
        padding-block: spacing(hg);
        position: relative;

        &--tall {
            min-height: $hero-content-min-height-tall;
        }
    }

    &__heading {
        position: relative;
        z-index: 2;
        color: inherit;
        margin: 0;

        &--giant {
            display: inline-flex;
            font-size: $font-size-giant;
            line-height: $line-height-extra-small;
            text-transform: uppercase;
        }
    }

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }

    &__tags {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        gap: spacing(sm);
        padding-left: spacing(md);

        .tag {
            white-space: nowrap;
        }

        &-column {
            &-title {
                font-weight: $font-weight-semi-bold;
                font-size: $font-size-small;
                color: var(--tertiary-color);
                position: relative;
                z-index: 2;
            }
        }

        &-list-wrapper {
            display: flex;
            align-items: center;
            gap: spacing(tn);
        }

        &-list-scroll-wrapper {
            position: relative;

            &:after,
            &:before {
                content: '';
                display: block;
                width: 40px;
                height: 100%;
                position: absolute;
                right: 0;
                top: 0;
                background-color: var(--background-color);
                mask-image: linear-gradient(90deg, transparent, rgba(246, 247, 247, 1) 100%);
                z-index: 1;
            }

            &:before {
                right: auto;
                left: -20px;
                mask-image: linear-gradient(90deg, rgba(246, 247, 247, 1) 0, transparent);
            }
        }

        &-list {
            display: flex;
            gap: spacing(tn);
            align-items: center;
            max-width: 300px;
            overflow-x: auto;
            padding-inline: 28px 40px;
            margin-left: -20px;

            &::-webkit-scrollbar {
                display: none;
            }
        }
    }

    &__lead, p {
        margin-block: spacing(md) 0;
    }

    &__lead {
        max-width: 60%;

        a {
            color: inherit;
            font-weight: $font-weight-semi-bold;
            text-decoration: underline;
        }
    }

    &__image-container {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        width: 100%;
        height: $hero-image-container-height-base;
        position: absolute;
        z-index: -1;
        top: 0;
        overflow: hidden;
    }

    &__image {
        width: 100%;
        height: 100%;
        object-fit: cover;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__mask {
        width: 100%;
        max-width: $hero-image-max-width;
        height: 100%;
        position: absolute;
        right: 0;
        bottom: -2px;
        overflow: hidden;
        z-index: 2;
        background-repeat: no-repeat;
        background-size: 100%;
        background-position: bottom center;
        user-select: none;
        pointer-events: none;
        background-image: url(#{$images-folder}hero-mask.svg);
    }

    .caption {
        margin: 0;
    }

    &__actions {
        display: flex;
        align-items: center;
        padding: spacing(sm);
        border-top: $border-width-base solid color(grey, 100);
        border-bottom: $border-width-base solid color(grey, 100);
    }

    &--full {
        box-sizing: content-box;
        margin-top: 0;

        #{$hero} {
            &__container {
                padding-inline: 0;
            }

            &__content {
                background-color: transparent;
                padding-block: 0;
            }

            &__heading {
                margin-block: spacing(hg) spacing(md);
                color: var(--secondary-color);
            }
        }
    }

    &__links {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: spacing(xl);
        margin-block: spacing(md) 0;

        .button {
            &--link {
                color: var(--negative-color);
            }
        }
    }
}

@media only screen and (max-width: map-get($breakpoints, xl)) {
    .hero {
        &__container {
            padding-inline: var(--side-padding-xl);
        }
    }
}


@media only screen and (max-width: map-get($breakpoints, lg)) {
    .hero {
        margin-block: 0 spacing(lg);
        box-sizing: border-box;

        &__container {
            padding-inline: 0;
        }

        &__heading {
            &--giant {
                font-size: $font-size-extra-huge;
            }
        }

        &__header {
            padding-block: var(--side-padding-lg) 0;
            padding-inline: var(--side-padding-lg);
        }

        &__content {
            padding-inline: var(--side-padding-lg);
        }
    }
}

@media only screen and (max-width: map-get($breakpoints, md)) {
    .hero {
        &__heading {
            &--giant {
                font-size: $font-size-extra-huge;
            }
        }

        &__mask {
            width: 104%;
            left: -2%;
            bottom: -1px;
        }

        &__links {
            flex-direction: column;
        }

        &__header {
            padding-block: var(--side-padding-md) 0;
            padding: var(--side-padding-md);
        }

        &__content {
            padding-inline: var(--side-padding-md);
        }
    }
}

@media only screen and (max-width: map-get($breakpoints, md)) {
    .hero {
        &__header {
            flex-direction: column;
            align-items: flex-start;
            padding: var(--side-padding-sm);
        }

        &__tags {
            flex-wrap: wrap;
            gap: spacing(tn);
            padding-top: spacing(sm);
            max-width: 100%;

            &-column {
                max-width: 100%;
            }

            &-list {
                max-width: 100vw;

                &-wrapper {
                    max-width: 100%;
                }

                &-scroll-wrapper {
                    max-width: 80%;
                }
            }
        }

        &__lead {
            max-width: none;
        }

        .caption {
            &--sm {
                font-size: $font-size-extra-small;
            }
        }

        &__content {
            padding-inline: var(--side-padding-sm);
        }
    }
}
