small {
    font-size: $font-size-small;
}

strong {
    font-weight: $font-weight-semi-bold;
}

p, small, em, i, strong {
    line-height: $line-height-base;
}

p {
    margin-bottom: spacing(md);
}

.columns {
    &-2 {
        column-count: 2;
    }
}

.font {
    &-size {
        &--lead {
            font-size: $font-size-medium;
            font-weight: $font-weight-medium;
            margin-bottom: spacing(xl);
        }
        &--md {
            font-size: $font-size-medium;
        }
    }
}

:root {
    --font-size-lead: #{$font-size-medium};
}

h1, h2, h3, h4, h5, h6 {
    @extend .heading;
}

h1 {
    @extend .heading--1;
}

h2 {
    @extend .heading--2;
}

h3 {
    @extend .heading--3;
}

h4 {
    @extend .heading--4;
}

em, i {
    font-style: italic;
}

dl {
    font-size: $font-size-small;
}

dt {
    font-weight: $font-weight-medium;
}

dd {
    font-weight: $font-weight-semi-bold;
}

@media only screen and (max-width: map-get($breakpoints, sm)) {
    p {
        font-size: $font-size-small;
    }

    .font {
        &-size {
            &--lead {
                font-size: $font-size-base;
                font-weight: $font-weight-medium;
            }
            &--md {
                font-size: $font-size-base;
            }
        }
    }

    .columns {
        &-2 {
            &-sm--off {
                column-count: 1;
            }
        }
    }
}
