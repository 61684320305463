$carousel-dot-size: 12px;

.carousel {
    $carousel: &;

    &__item {
        display: block;
        float: left;
        width: 100%;
        position: relative;
        overflow: hidden;

        #{$carousel}__image {
            &--parallax {
                display: block;
                width: 100%;
                height: 100%;
                position: absolute;
                z-index: 1;
                background-size: cover;
                background-repeat: no-repeat;
            }
        }

        video,
        iframe {
            min-width: 100%;
            min-height: 100%;
            opacity: .2;
        }
    }

    &__wrapper {
        width: 100%;
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: 1;
        padding-left: spacing(hg);
        padding-bottom: spacing(xl);

        &:after {
            content: '';
            display: block;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 0;
            background: linear-gradient(to bottom, rgba(color(black), 0) 0%, rgba(color(black), .6) 100%);
        }
    }

    &__content {
        position: relative;
        z-index: 1;
    }

    &__heading {
        text-transform: uppercase;
        margin-bottom: spacing(sm);
        font-size: $font-size-huge;
        line-height: $line-height-small;
        text-shadow: 1px 2px 2px rgba(color(black), .25);
    }

    &__lead {
        font-size: $font-size-large;
        font-weight: $font-weight-regular;
        font-style: $font-style-italic;
    }

    &__dots {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: spacing(hg) 0 spacing(base) 0;

        &.disabled {
            padding: 0;
            display: none;
        }

        &--inside {
            width: 100%;
            position: absolute;
            left: 0;
            bottom: 0;
        }

        &--hide {
            &-desktop {
                display: none;
            }
        }
    }

    &__dot {
        @extend .button;
    }

    & &__dot {
        width: $carousel-dot-size;
        height: $carousel-dot-size;
        min-width: 0;
        padding: 0;
        border-radius: $carousel-dot-size;
        border: $border-width-base solid color(black);
        background-color: transparent;
        margin-right: spacing(sm);

        &.active {
            background-color: color(orange);
            border-color: color(orange);
        }

        &:last-child {
            margin-right: 0;
        }
    }

    &__extra-navigation {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        padding-top: spacing(md);

        &-item {
            padding: spacing(base) spacing(base) spacing(xs) spacing(base);
            margin: 0 spacing(tn);
            cursor: pointer;
            position: relative;
            transition: $transition-fast;
            font-size: $font-size-small;
            font-weight: $font-weight-bold;
            color: color(grey, 100);

            &:after {
                content: '';
                display: block;
                width: 100%;
                height: $border-width-base;
                position: absolute;
                left: 0;
                bottom: 0;
                background-color: color(orange);
                opacity: 0;
                transition: $transition-fast;
            }

            &.active {
                color: color(orange);
                padding: spacing(xs) spacing(base) spacing(base) spacing(base);

                &:after {
                    opacity: 1;
                }
            }
        }
    }
}

@media only screen and (max-width: $screen-md) {
    .carousel {
        &__dots {
            &--bg {
                background: linear-gradient(to bottom, rgba(color(grey), 0) 0%, rgba(color(grey), 1) 100%);
            }

            &--hide {
                &-desktop {
                    display: flex;
                }
            }
        }

        &__nav {
            &--hide {
                &-tablet {
                    display: none;
                }
            }
        }

        &__wrapper {
            padding: 0 spacing(lg) spacing(lg);
        }

        &__extra-navigation {
            display: none;
        }
    }
}

@media only screen and (max-width: $screen-sm) {
    .carousel {
        &__heading {
            font-size: $font-size-heavy;
            line-height: $line-height-small;
        }

        &__wrapper {
            padding: 0 spacing(base) spacing(lg) spacing(base);
        }
    }
}
