:root {
    --side-padding: var(--side-padding-lg);
}

.page {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 1;
    background-color: var(--background-color);
    padding: var(--side-padding)
}

@media only screen and (max-width: map-get($breakpoints, lg)) {
    :root {
        --side-padding: var(--side-padding-md);
    }
}


@media only screen and (max-width: map-get($breakpoints, sm)) {
    :root {
        --side-padding: 0;
    }
}
