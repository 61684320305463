.page-content {
    ul, p {
        margin-bottom: spacing(base);
    }
}

.page-content,
.post-content {
    ul {
        list-style-position: outside;
        padding-left: .5rem;

        li {
            display: table-row;
            flex-direction: row;

            &:before {
                content: "•";
                padding-right: .75rem;
                font-size: 1.25em;
                color: currentColor;
                display: table-cell;
            }
        }
    }
}
