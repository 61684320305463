.hr {
    width: 100%;
    height: 0;
    margin: spacing(base) 0;
    border: none;
    border-top: $border-width-bold solid var(--tertiary-color);
    display: block;
    box-sizing: content-box;

    &--faded {
        opacity: .5;
    }

    &--medium {
        margin: spacing(md) 0;
    }

    &--small {
        margin: spacing(sm) 0;
    }

    &--dashed {
        border-top-style: dashed;
    }

    &--no-margin {
        margin: 0;
    }
}
