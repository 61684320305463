.section {
    margin-bottom: spacing(hg);
    padding-block: spacing(xl);
    position: relative;
}

@media only screen and (max-width: map-get($breakpoints, lg)) {
    .section {
        padding-inline: spacing(md);
        padding-block: spacing(base) spacing(md);
    }
}
@media only screen and (max-width: map-get($breakpoints, sm)) {
    .section {
        margin-bottom: spacing(xl);
    }
}
