html {
    @each $spacing-key, $spacing-value in $spacings {
        @if $spacing-key == base {
            .padding {
                padding: $spacing-value;

                @each $direction-key in $directions {
                    &--#{$direction-key} {
                        padding-#{$direction-key}: $spacing-value;
                    }
                }
            }
        } @else {
            .padding-#{$spacing-key} {
                padding: $spacing-value;

                @each $direction-key in $directions {
                    &--#{$direction-key} {
                        padding-#{$direction-key}: $spacing-value;
                    }
                }
            }
        }
    }

    .padding {
        @each $direction-key in $directions {
            &-auto--#{$direction-key} {
                padding-#{$direction-key}: auto;
            }
        }
    }

    .no-padding {
        padding: 0 !important;

        @each $direction-key in $directions {
            &--#{$direction-key} {
                padding-#{$direction-key}: 0 !important;
            }
        }
    }
}

@media only screen and (max-width: map-get($breakpoints, md)) {
    html {
        .no-padding-tablet {
            padding: 0 !important;

            @each $direction-key in $directions {
                &--#{$direction-key} {
                    padding-#{$direction-key}: 0 !important;
                }
            }
        }
        
        @each $spacing-key, $spacing-value in $spacings {
            @if $spacing-key == base {
                .padding-tablet {
                    padding: $spacing-value;

                    @each $direction-key in $directions {
                        &--#{$direction-key} {
                            padding-#{$direction-key}: $spacing-value;
                        }
                        &--#{$direction-key}--neg {
                            padding-#{$direction-key}: -$spacing-value;
                        }
                    }
                }
            } @else {
                .padding-tablet-#{$spacing-key} {
                    padding: $spacing-value;

                    @each $direction-key in $directions {
                        &--#{$direction-key} {
                            padding-#{$direction-key}: $spacing-value;
                        }
                        &--#{$direction-key}--neg {
                            padding-#{$direction-key}: -$spacing-value;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: map-get($breakpoints, sm)) {
    html {
        .no-padding-mobile {
            padding: 0 !important;

            &--inline {
                padding-inline: 0 !important;
            }

            @each $direction-key in $directions {
                &--#{$direction-key} {
                    padding-#{$direction-key}: 0 !important;
                }
            }
        }

        @each $spacing-key, $spacing-value in $spacings {
            @if $spacing-key == base {
                .padding-mobile {
                    padding: $spacing-value;

                    @each $direction-key in $directions {
                        &--#{$direction-key} {
                            padding-#{$direction-key}: $spacing-value;
                        }
                    }
                }
            } @else {
                .padding-mobile-#{$spacing-key} {
                    padding: $spacing-value;

                    @each $direction-key in $directions {
                        &--#{$direction-key} {
                            padding-#{$direction-key}: $spacing-value;
                        }
                    }
                }
            }
        }
    }
}
