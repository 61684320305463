.main {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    min-height: 100vh;
    position: relative;
    //overflow: hidden;
}

@media only screen and (max-width: map-get($breakpoints, md)) {
    .main {
    }
}
