$colors: (
    black: (
        base: black
    ),
    white: (
        base: white
    ),
    grey: (
        base: #626065,
        25: #F1F1F1,
        50: #EBEBEC,
        100: #D8D7D9,
        200: #C4C3C5,
        300: #9D9C9F,
        400: #89888C,
        500: #626065,
        600: #4F4C52,
        700: #3B383F,
        800: #2F2D32,
        900: #232226
    ),
    cement: (
        base: #C9C6C2,
        500: #C9C6C2
    ),
    sand: (
        base: #D6CAB9,
        25: #FFFAF4,
        50: #FCF5EF,
        100: #EAE1D4,
        200: #D6CAB9,
        300: #DABDA9
    ),
    brown: (
        base: #3B1A06,
        500: #3B1A06
    ),
    yellow: (
        base: #F5AF51,
        400: #F5AF51
    ),
    orange: (
        base: #EC4E20,
        400: #EC4E20
    ),
    system: (
        red: #FF1B1C,
        amber: #FFE900,
        green: #0CCE6B
    ),
    brand: (
        linkedin: #2F65BD
    ),
);

@function color($color, $tone: 'base')
{
    @return map-get(map_get($colors, $color), $tone);
}

@each $color-key, $color-value in $colors {
    @each $tone-key, $tone-value in $color-value {
        @if $tone-key == base {
            .color#{'--' + $color-key} {
                color: $tone-value !important;
            }

            .fill#{'--' + $color-key} {
                background-color: $tone-value !important;
            }
        } @else {
            .color#{'--' + $color-key + '-' + $tone-key} {
                color: $tone-value !important;
            }

            .fill#{'--' + $color-key + '-' + $tone-key} {
                background-color: $tone-value !important;
            }
        }
    }
}

:root {
    @each $color-key, $color-value in $colors {
        @each $tone-key, $tone-value in $color-value {
            @if $tone-key == base {
                --color#{'-' + $color-key}: #{$tone-value};
            } @else {
                --color#{'-' + $color-key + '-' + $tone-key}: #{$tone-value};
            }
        }
    }
}

:root {
    --background-color: #{color(sand, 200)};
    --primary-color: #{color(orange)};
    --secondary-color: #{color(orange)};
    --tertiary-color: #{color(brown)};
    --negative-color: #{color(white)};
    --color-accent-primary: #{color(orange)};
    --color-accent-negative: #{color(white)};
    --highlight-bg-color: var(--color-accent-primary);
    --highlight-color: var(--color-accent-negative);
}
