@use 'sass:math';

$form-input-height: 50px;
$form-input-border-radius: 0;
$form-input-border-width: $border-width-thin;
$form-input-vertical-padding: 15px;
$form-input-horizontal-padding: spacing(base);
$form-input-border-color-base: color(grey, 100);
$form-input-font-color-base: var(--tertiary-color);
$form-input-placeholder-color-base: color(grey, 400);
$form-input-bg-color-base: color(white);
$form-input-font-size: $font-size-small;

$form-input-bg-color-focus: color(white);
$form-input-border-color-focus: color(orange);
$form-input-border-width-focus: $border-width-bold;
$form-input-placeholder-color-focus: $form-input-placeholder-color-base;
$form-input-font-color-focus: $form-input-font-color-base;

$form-input-bg-color-error: rgba(color(system, red), .03);
$form-input-bg-color-error-focus: color(white);
$form-input-border-color-error: color(system, red);
$form-input-border-color-error-focus: color(system, red);
$form-input-placeholder-color-error: rgba(color(system, red), .3);
$form-input-placeholder-color-error-focus: rgba(color(system, red), .5);
$form-input-font-color-error: $form-input-font-color-base;

$form-input-bg-color-success: rgba(color(system, green), .03);
$form-input-bg-color-success-focus: color(white);
$form-input-border-color-success: color(system, green);
$form-input-border-color-success-focus: color(system, green);
$form-input-placeholder-color-success: rgba(color(system, green), .3);
$form-input-placeholder-color-success-focus: rgba(color(system, green), .5);
$form-input-font-color-success: $form-input-font-color-base;

$form-input-bg-color-disabled: color(grey, 25);
$form-input-border-color-disabled: color(grey, 100);
$form-input-placeholder-color-disabled: color(grey, 300);
$form-input-font-color-disabled: $form-input-font-color-base;

$form-input-line-height-base: 26px;
$form-input-height-base: $form-input-height;
$form-input-group-width: 100%;

$form-icon-color-base: color(black);

.form {
    $form: &;

    &__input {
        display: block;
        padding: .5em $form-input-horizontal-padding;
        width: 100%;
        max-width: 100%;
        min-height: $form-input-height;
        box-shadow: 0 0 0 $form-input-border-width $form-input-border-color-base inset;
        border: none;
        appearance: none;
        border-radius: $form-input-border-radius;
        background-color: $form-input-bg-color-base;
        font-family: $font-family-primary;
        font-size: $form-input-font-size;
        color: $form-input-font-color-base;
        position: relative;
        transition: all 300ms ease-in-out;
        box-sizing: border-box;

        &--auto {
            width: auto;
        }

        &-requirements {
            display: flex;
            align-items: center;
            gap: math.div(spacing(xs), 2);
            padding: 0;
            max-height: 0;
            transition: 0.28s;
            overflow: hidden;
            color: color(system, red);
            font-size: var(--font-size-small);
            font-style: italic;

            &:before {
                content: '';
                @extend .icon;
                @extend .icon-mask;
                @extend .icon-Warning;
                transition: transform 200ms ease-in-out;
                color: currentColor;
                width: iconSize(sm);
                height: iconSize(sm);
                margin: 0;
            }
        }

        &::placeholder,
        &--placeholder {
            font-style: italic;
            color: $form-input-placeholder-color-base;
        }

        &:hover,
        &--hover {
            box-shadow: 0 0 0 $form-input-border-width $form-input-border-color-focus inset;
        }

        &:focus,
        &--focus{
            color: $form-input-font-color-focus;
            box-shadow: 0 0 0 $form-input-border-width-focus $form-input-border-color-focus inset;
            background-color: $form-input-bg-color-focus;

            &::placeholder {
                color: $form-input-placeholder-color-focus;
            }
        }

        &:valid:not(:placeholder-shown):not(.form__select):not(.filter__search) {
            box-shadow: 0 0 0 $form-input-border-width $form-input-border-color-success inset;

            &:focus {
                box-shadow: 0 0 0 $form-input-border-width-focus $form-input-border-color-focus inset;
            }
        }

        &:invalid:not(:focus):not(:placeholder-shown):not(.form__select):not(.filter__search) {
            box-shadow: 0 0 0 $form-input-border-width-focus $form-input-border-color-error inset;
            background-color: rgba($form-input-border-color-error, .05);

            & ~ #{$form}__input-requirements {
                max-height: 100px;
                padding: spacing(xs) 0 0 0;
            }
        }

        &:invalid:focus:not(:placeholder-shown):not(.form__select):not(.filter__search) {
            box-shadow: 0 0 0 $form-input-border-width-focus $form-input-border-color-error inset;
            background-color: $form-input-bg-color-base;

            & ~ #{$form}__input-requirements {
                max-height: 100px;
                padding: spacing(xs) 0 0 0;
            }
        }

        &:disabled,
        &--disabled {
            background-color: $form-input-bg-color-disabled;
            border-color: $form-input-border-color-disabled;
            pointer-events: none;
        }

        &--error {
            background-color: $form-input-bg-color-error;
            border-color: $form-input-border-color-error;

            &::placeholder {
                color: $form-input-placeholder-color-error;
            }

            &:focus,
            &:active,
            &#{$form}__input--focus,
            &#{$form}__input--active {
                border-color: $form-input-border-color-error-focus;
                background-color: $form-input-bg-color-error-focus;

                &::placeholder {
                    color: $form-input-placeholder-color-error-focus;
                }
            }
        }

        &--success {
            background-color: $form-input-bg-color-success;
            border-color: $form-input-border-color-success;

            &::placeholder {
                color: $form-input-placeholder-color-success;
            }

            &:focus,
            &:active,
            &#{$form}__input--focus,
            &#{$form}__input--active {
                border-color: $form-input-border-color-success-focus;
                background-color: $form-input-bg-color-success-focus;

                &::placeholder {
                    color: $form-input-placeholder-color-success-focus;
                }
            }
        }

        &-group {
            display: flex;
            align-items: center;
            width: 100%;
            height: $form-input-height-base;
            position: relative;
            padding: 0 $form-input-horizontal-padding;

            &:hover {
                #{$form}__input-group-bg {
                    box-shadow: 0 0 0 $form-input-border-width $form-input-border-color-focus;
                }
            }

            #{$form}__input {
                border: none;
                box-shadow: none;
                background-color: transparent;
                padding: 0;
                margin-bottom: 0;
                border-radius: 0;
                position: relative;
                z-index: 1;

                &:disabled,
                &--disabled {
                    & + #{$form}__input-group-bg {
                        opacity: $form-input-bg-color-disabled;
                        pointer-events: none;
                    }
                }

                &:focus,
                &:active,
                &#{$form}__input--focus,
                &#{$form}__input--active {
                    & + #{$form}__input-group-bg {
                        box-shadow: 0 0 0 $form-input-border-width-focus $form-input-border-color-focus;
                        background-color: $form-input-bg-color-focus;

                        & + #{$form}__input-group-icon {
                            color: $form-input-font-color-focus;
                        }
                    }
                }

                &--error {
                    & + #{$form}__input-group-bg {
                        background-color: $form-input-bg-color-error;
                        box-shadow: 0 0 0 $form-input-border-width $form-input-border-color-error;
                    }

                    &:focus,
                    &:active,
                    &#{$form}__input--focus,
                    &#{$form}__input--active {
                        & + #{$form}__input-group-bg {
                            box-shadow: 0 0 0 $form-input-border-width-focus $form-input-border-color-error;
                            background-color: $form-input-bg-color-error-focus;
                        }
                    }
                }

                &--success {
                    & + #{$form}__input-group-bg {
                        background-color: $form-input-bg-color-success;
                        box-shadow: 0 0 0 $form-input-border-width $form-input-border-color-success;
                    }

                    &:focus,
                    &:active,
                    &#{$form}__input--focus,
                    &#{$form}__input--active {
                        & + #{$form}__input-group-bg {
                            box-shadow: 0 0 0 $form-input-border-width-focus $form-input-border-color-success-focus;
                            background-color: $form-input-bg-color-success-focus;
                        }
                    }
                }
            }

            &-bg {
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                border: none;
                box-shadow: 0 0 0 $form-input-border-width $form-input-border-color-base;
                border-radius: $form-input-border-radius;
                background-color: $form-input-bg-color-base;
                z-index: 0;
                transition: $transition-base;

                & + #{$form}__input-group-icon {
                    color: $form-icon-color-base;
                }
            }

            &-input {
                display: flex;
                align-items: center;
                width: $form-input-group-width;
            }

            &-icon {
                display: inline-flex;
                margin-right: 0;
                margin-left: math.div($form-input-horizontal-padding, 2);
                z-index: 1;
            }
        }
    }
}

textarea {
    &.form__input {
        padding: $form-input-horizontal-padding;
    }
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
    -webkit-appearance:none;
}
