.form {
    &__label {
        display: flex;
        align-items: center;
        width: 100%;
        margin: spacing(tn) 0;
        font-family: $font-family-primary;
        font-size: $font-size-small;
        font-weight: $font-weight-semi-bold;
        color: var(--color-tertiary);

        &--required {
            &:after {
                content: "*";
                margin: -4px 0 0 4px;
                color: color(system, red);
            }
        }
    }
}
