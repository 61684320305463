.card {
    $card: &;

    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    width: 100%;
    min-height: 100%;
    background-color: color(white);
    box-shadow: $box-shadow-large;

    &__content {
        height: 100%;
        padding-block: spacing(hg) 0;
        padding-inline: spacing(hg);

        > * {
            max-width: 420px;
        }
    }

    &__image {
        width: 100%;

        & + #{$card}__content {
            padding-inline: spacing(xl);
            padding-top: spacing(lg);

            & + #{$card}__footer {
                padding-bottom: spacing(xl);
            }
        }

        img {
            width: 100%;
            object-fit: cover;
        }
    }

    &__heading {
        text-align: center;
        margin-bottom: spacing(md);
    }

    &__footer {
        text-align: center;
        padding-bottom: spacing(hg);
    }
}

@media only screen and (max-width: $screen-sm) {
    .card {
        $card: &;

        &__content {
            padding-block: spacing(lg) 0;
            padding-inline: spacing(lg);
        }

        &__footer {
            width: 100%;
            padding-inline: spacing(lg);
            padding-bottom: spacing(lg);

            .button {
                min-width: 0;
                width: 100%;
            }
        }

        &__image {
            & + #{$card}__content {
                padding-inline: spacing(md);
                padding-top: spacing(base);

                & + #{$card}__footer {
                    padding-bottom: spacing(md);
                }
            }
        }

        &__heading {
            text-align: left;
        }
    }
}
