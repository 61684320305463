html body {
    @each $spacing-key, $spacing-value in $spacings {
        @if $spacing-key == base {
            .margin {
                margin: $spacing-value;

                @each $direction-key in $directions {
                    &--#{$direction-key} {
                        margin-#{$direction-key}: $spacing-value;
                    }

                    &--#{$direction-key}--neg {
                        margin-#{$direction-key}: -$spacing-value;
                    }
                }
            }
        } @else {
            .margin-#{$spacing-key} {
                margin: $spacing-value;

                @each $direction-key in $directions {
                    &--#{$direction-key} {
                        margin-#{$direction-key}: $spacing-value;
                    }

                    &--#{$direction-key}--neg {
                        margin-#{$direction-key}: -$spacing-value;
                    }
                }
            }
        }
    }

    .margin {
        @each $direction-key in $directions {
            &-auto--#{$direction-key} {
                margin-#{$direction-key}: auto;
            }
        }

        &-auto--block {
            margin-block: auto;
        }

        &-auto--inline {
            margin-inline: auto;
        }
    }

    .no-margin {
        margin: 0 !important;

        @each $direction-key in $directions {
            &--#{$direction-key} {
                margin-#{$direction-key}: 0 !important;
            }
        }

        &--block {
            margin-block: 0 !important;
        }

        &--inline {
            margin-inline: 0 !important;
        }
    }
}

@media only screen and (min-width: map-get($breakpoints, md) + 1) {
    html {
        .no-margin-desktop {
            margin: 0 !important;

            @each $direction-key in $directions {
                &--#{$direction-key} {
                    margin-#{$direction-key}: 0 !important;
                }
            }
        }
    }
}

@media only screen and (max-width: map-get($breakpoints, md)) {
    html {
        .no-margin-tablet {
            margin: 0 !important;

            @each $direction-key in $directions {
                &--#{$direction-key} {
                    margin-#{$direction-key}: 0 !important;
                }
            }
        }

        .margin-auto-tablet {
            margin: auto !important;

            @each $direction-key in $directions {
                &--#{$direction-key} {
                    margin-#{$direction-key}: auto !important;
                }
            }
        }

        @each $spacing-key, $spacing-value in $spacings {
            @if $spacing-key == base {
                .margin-tablet {
                    margin: $spacing-value;

                    @each $direction-key in $directions {
                        &--#{$direction-key} {
                            margin-#{$direction-key}: $spacing-value;
                        }
                        &--#{$direction-key}--neg {
                            margin-#{$direction-key}: -$spacing-value;
                        }
                    }
                }
            } @else {
                .margin-tablet-#{$spacing-key} {
                    margin: $spacing-value;

                    @each $direction-key in $directions {
                        &--#{$direction-key} {
                            margin-#{$direction-key}: $spacing-value;
                        }
                        &--#{$direction-key}--neg {
                            margin-#{$direction-key}: -$spacing-value;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: map-get($breakpoints, sm)) {
    html {
        .no-margin-mobile {
            margin: 0 !important;

            @each $direction-key in $directions {
                &--#{$direction-key} {
                    margin-#{$direction-key}: 0 !important;
                }
            }
        }

        .margin-auto-mobile {
            margin: auto !important;

            @each $direction-key in $directions {
                &--#{$direction-key} {
                    margin-#{$direction-key}: auto !important;
                }
            }
        }

        @each $spacing-key, $spacing-value in $spacings {
            @if $spacing-key == base {
                .margin-mobile {
                    margin: $spacing-value;

                    @each $direction-key in $directions {
                        &--#{$direction-key} {
                            margin-#{$direction-key}: $spacing-value;
                        }
                        &--#{$direction-key}--neg {
                            margin-#{$direction-key}: -$spacing-value;
                        }
                    }
                }
            } @else {
                .margin-mobile-#{$spacing-key} {
                    margin: $spacing-value;

                    @each $direction-key in $directions {
                        &--#{$direction-key} {
                            margin-#{$direction-key}: $spacing-value;
                        }
                        &--#{$direction-key}--neg {
                            margin-#{$direction-key}: -$spacing-value;
                        }
                    }
                }
            }
        }
    }
}
