.display
{
    &--block
    {
        display: block !important;
    }

    &--flex
    {
        display: flex !important;
    }

    &--inline
    {
        display: inline !important;
    }

    &--inline-block
    {
        display: inline-block !important;
    }

    &--none
    {
        display: none !important;
    }

    &--full
    {
        width: 100%;
    }
}

@media only screen and (min-width: $screen-sm) and (max-width: $screen-md + 1)
{
    .hide
    {
        &--md
        {
            display: none !important;
        }
    }
}

@media only screen and (max-width: $screen-sm - 1)
{
    .hide
    {
        &--sm
        {
            display: none !important;
        }
    }

    .display
    {
        &--flex
        {
            flex-direction: column;
        }
    }
}

@media only screen and (min-width: $screen-md)
{
    .hide
    {
        &--lg
        {
            display: none !important;
        }
    }
}
