@use 'sass:math';

.post-content {
    font-family: $font-family-primary;
    line-height: $line-height-large;

    h1, h2, h3, h4, h5, h6 {
        @extend .heading;
        margin-bottom: spacing(lg);

        >   *, > strong {
            font-weight: inherit;
        }
    }

    h1 {
        @extend .heading--1;
    }

    h2 {
        @extend .heading--2;
    }

    h3 {
        @extend .heading--3;
    }

    h4 {
        @extend .heading--4;
        margin-top: spacing(xl);
    }

    h1, h2 {
        >   *, > strong {
            font-weight: inherit;
        }
    }

    h3, h4 {
        >   *, > strong {
            font-weight: inherit;
        }
    }

    p, small, em, i, strong {
        line-height: $line-height-medium;
    }

    p, ul, ol {
        margin-bottom: spacing(md);
    }

    ul, ol {
        padding-left: spacing(md);
    }

    ul {
        list-style: disc;
    }

    ol {
        list-style: decimal;
    }

    a:not(.button) {
        &:hover {
            text-decoration: underline;
            color: color(orange);
        }
    }

    blockquote {
        position: relative;
        left: calc(-100% / 12);
        font-size: $font-size-large;
        line-height: $line-height-large;
        padding-left: spacing(lg);
        margin: spacing(xl) 0;

        &:before {
            content: '';
            display: block;
            width: $border-width-base;
            height: calc(100% - #{math.div($line-height-large, 2)});
            background-color: color(orange);
            position: absolute;
            top: math.div($line-height-large, 4.5);
            left: 0;
        }
    }
}

@media only screen and (max-width: map-get($breakpoints, sm)) {
    .post-content {
        padding-inline: var(--side-padding-sm);

        blockquote {
            margin-left: 0;
            left: 0;
        }
    }
}
