.read-more {
    $root: &;

    &__container {}

    &__less {
        margin-bottom: 0 !important;
        transition: all 300ms ease-in-out;

        &.toggled {
            margin-bottom: spacing(base) !important;
        }

        p {
            margin-bottom: 0 !important;
        }
    }

    &__more {
        overflow-y: hidden;
        max-height: 0;
        transition: max-height 200ms ease-in-out;

        &.toggled {
            max-height: 1000px;
        }
    }

    &__toggle {
        @extend .link;

        display: inline-flex;
        align-items: center;
        height: auto;
        line-height: 1;
        font-size: $font-size-small;
        font-weight: $font-weight-bold;
        color: color(orange);
        cursor: pointer;
        position: relative;
        transition: all 250ms ease-in-out;
        padding-block: spacing(xs);

        &--less {
            display: block;
            height: 40px;
            margin-top: - spacing(xs);
            margin-bottom: -20px;
            width: 100%;

            &.invisible {
                display: none;
            }

            #{$root}__toggle-icon {
                position: static;
            }
        }

        &:hover {
            color: color(orange);
        }

        &-icon {
            width: 1rem;
            min-width: 1rem;
            height: 1rem;
            min-height: 1rem;
            position: absolute;
            right: -1.25rem;
            transition: all 150ms ease-in-out;
        }

        &.invisible {
            display: none;
            opacity: 0;
            height: 0;
            line-height: 0;
            visibility: hidden;
            pointer-events: none;
        }
    }
}
