.separator {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-block: spacing(xl);
    margin-bottom: spacing(xl);

    svg {
        stroke-width: 1px;
        stroke: color(grey, 200);
        flex-shrink: .25;
    }
}

@media only screen and (max-width: $screen-md) {
    .separator {
        padding-block: spacing(sm);
        margin-bottom: spacing(lg);
    }
}
