.listings {
    $root: &;

    &__item {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
    }

    &__content {
        display: flex;
        flex-direction: column;
    }

    &__image {
        position: relative;
        z-index: 1;
        margin-bottom: - spacing(lg);
    }

    &__reviews {
        display: flex;
        justify-content: space-between;
        position: absolute;
        z-index: 2;
        width: 100%;

        &-column {
            width: calc(var(--review-width) * 1.25);
            display: flex;
            flex-direction: column;
            gap: spacing(lg);

            &:last-child {
                align-items: flex-end;
            }
        }
    }

    &__heading {
        width: 100%;
        text-align: center;
        position: relative;
        z-index: 1;

        &-actions {
            @extend .details__footer-actions;
            flex-direction: column;

            &-buttons {
                display: flex;
                width: 100%;
                justify-content: center;
                gap: spacing(xs);
                margin-bottom: spacing(sm);

                .button {
                    min-width: 100px;
                }
            }

            > p {
                margin-bottom: 0;
            }
        }
    }

    &__title {
        margin-bottom: spacing(base);
    }

    &__location {
        display: block;
        font-size: $font-size-large;
        font-family: $font-family-secondary;
        margin-bottom: spacing(base);
    }

    &__metas {
        font-weight: $font-weight-semi-bold;
    }

    &__navigation {
        $navigation: &;

        display: flex;
        flex-direction: row;
        position: -webkit-sticky;
        position: sticky;
        z-index: 10;
        bottom: var(--side-padding);
        margin-top: spacing(hg);
        width: 100%;
        background: color(sand, 100);

        &:after {
            content: '';
            width: 100%;
            height: spacing(hg);
            display: block;
            position: absolute;
            z-index: 11;
            top: 100%;
            background-color: var(--background-color);
        }

        &-item {
            flex-grow: 1;
            flex-basis: 0;
            cursor: pointer;

            &:first-child {
                #{$navigation} {
                    &-link {
                        border-left: none;
                    }
                }
            }

            &:last-child {
                #{$navigation} {
                    &-link {
                        border-right: none;
                    }
                }
            }
        }

        &-link {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 88px;
            padding: spacing(md) spacing(lg);
            border-top: $border-width-bold solid var(--tertiary-color);
            border-bottom: $border-width-bold solid var(--tertiary-color);
            border-bottom-color: transparent;
            position: relative;
            overflow: hidden;
            user-select: none;

            &.active {
                background-color: color(sand, 25);
                border-top-color: transparent;
                border-bottom-color: var(--tertiary-color);
                border-left: $border-width-bold solid var(--tertiary-color);
                border-right: $border-width-bold solid var(--tertiary-color);

                #{$navigation} {

                    &-button {
                        margin-top: 0;
                    }

                    &-text {
                        &:after {
                            //width: 100%;
                        }
                    }
                }
            }
        }

        &-text {
            display: flex;
            justify-content: center;
            width: 100%;
            font-weight: $font-weight-black;
            font-size: $font-size-medium;
            letter-spacing: -0.02rem;
            color: var(--tertiary-color);
            line-height: 1;
            text-align: center;
            transition: all 300ms ease-in-out;
            position: relative;
            white-space: nowrap;

            &:after {
                content: '';
                display: block;
                width: 1px;
                height: 1px;
                transition: all 300ms ease-in-out;
            }
        }

        &-button {
            position: absolute;
            right: spacing(md);
            margin-top: 100%;
        }
    }

    &__cards {
        display: flex;
        align-items: flex-start;
        justify-content: space-evenly;
        gap: spacing(xl);
        padding-inline: spacing(xl);
    }

    &__card {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 480px;
        gap: spacing(md);

        &-image {
            border-radius: $border-radius-extra-large;
            overflow: hidden;
        }

        .listings {
            &__heading {
                color: var(--tertiary-color);
                padding-top: 0;
            }

            &__title {
                font-family: $font-family-epic;
                font-weight: initial;
                font-size: $font-size-huge;
                margin-bottom: spacing(tn);
            }

            &__location {
                font-size: $font-size-medium;
            }
        }
    }
}

@media only screen and (max-width: $screen-lg) {
    .listings {
        &__item {
            flex-direction: column-reverse;
        }

        &__reviews {
            width: 100%;
            flex-direction: row;
            align-items: flex-start;
            justify-content: flex-start;
            position: relative;
            gap: spacing(lg);
            padding: spacing(lg);
            padding-bottom: 0;
            overflow: scroll;

            &-column {
                width: auto;
                flex-direction: row;
                justify-content: flex-start;
                align-items: flex-start !important;
                gap: spacing(lg);

                .review {
                    width: var(--review-width);
                    margin: 0 !important;
                    text-align: left;

                    &__content {
                        width: 100%;
                    }
                }
            }
        }

        &__title {
            margin-bottom: 0;
        }

        &__navigation {
            &-button {
                display: none;
            }
        }
    }
}

@media only screen and (max-width: $screen-lg) {
    .listings {
        &__navigation {
            &-text {
                &:after {
                    display: none;
                }
            }
        }
    }
}
@media only screen and (max-width: $screen-md) {
    .listings {
        &__navigation {
            display: none;
        }

        &__heading {
            padding-top: spacing(md);
        }

        &__title {
            margin-bottom: spacing(xs);
        }

        &__location {
            font-size: $font-size-medium;
            margin-bottom: spacing(tn);
        }
    }
}

@media only screen and (max-width: $screen-sm) {
    .listings {
        &__cards {
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .listings__location {
                margin-bottom: spacing(md);
            }
        }
        &__reviews {
            padding-inline: 0;
        }
    }
}
