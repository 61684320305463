@use 'sass:math';

$button-side-padding-base: 30px;
$button-side-padding-small: 14px;
$button-label-side-padding-base: 15px;
$button-transition-base: all 200ms ease-in-out;
$button-letter-spacing-base: 0.084rem;
$button-letter-spacing-small: 0.048rem;
$button-font-size-base: $font-size-small;
$button-font-size-small: $font-size-extra-small;
$button-font-weight-base: $font-weight-black;
$button-border-width-base: $border-width-base;
$button-height-base: 44px - $button-border-width-base * 2;
$button-height-small: 32px - $button-border-width-base * 2;
$button-border-radius-base: $button-height-base;

$button-primary-bg-initial: color(orange);
$button-primary-bg-hover: color(grey, 500);
$button-primary-border-color-initial: color(orange);
$button-primary-border-color-hover: color(grey, 500);
$button-primary-color-initial: color(white);
$button-primary-color-hover: color(white);

$button-secondary-bg-initial: transparent;
$button-secondary-bg-hover: color(orange);
$button-secondary-border-color-initial: color(orange);
$button-secondary-border-color-hover: color(orange);
$button-secondary-color-initial: color(orange);
$button-secondary-color-hover: color(white);

$button-ghost-default-bg-initial: transparent;
$button-ghost-default-bg-hover: color(orange);
$button-ghost-default-border-color-initial: color(orange);
$button-ghost-default-border-color-hover: color(orange);
$button-ghost-default-color-initial: color(orange);
$button-ghost-default-color-hover: color(white);

$button-ghost-orange-bg-initial: color(white);
$button-ghost-orange-bg-hover: color(orange);
$button-ghost-orange-border-color-initial: color(orange);
$button-ghost-orange-border-color-hover: color(orange);
$button-ghost-orange-color-initial: color(orange);
$button-ghost-orange-color-hover: color(white);

$button-ghost-black-bg-initial: transparent;
$button-ghost-black-bg-hover: color(black);
$button-ghost-black-border-color-initial: color(black);
$button-ghost-black-border-color-hover: color(black);
$button-ghost-black-color-initial: color(black);
$button-ghost-black-color-hover: color(white);

$button-ghost-white-bg-initial: color(white);
$button-ghost-white-bg-hover: color(orange);
$button-ghost-white-border-color-initial: color(white);
$button-ghost-white-border-color-hover: color(orange);
$button-ghost-white-color-initial: color(black);
$button-ghost-white-color-hover: color(white);

$button-link-bg-initial: transparent;
$button-link-bg-hover: transparent;
$button-link-border-color-initial: transparent;
$button-link-border-color-hover: transparent;
$button-link-color-initial: color(orange);
$button-link-color-hover: color(orange, 500);

$button-icon-spacing-base: spacing(tn);
$button-icon-spacing-small: spacing(tn);

.button {
    $root: &;

    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 auto;
    width: auto;
    height: $button-height-base;
    min-width: $button-height-base - $button-side-padding-base * 2;
    line-height: $button-height-base;
    padding: 0 $button-side-padding-base;
    position: relative;
    transition: $button-transition-base;
    letter-spacing: $button-letter-spacing-base;
    cursor: pointer;
    font-size: $button-font-size-base;
    font-weight: $button-font-weight-base;
    white-space: nowrap;
    appearance: none;
    border-radius: $button-border-radius-base;
    text-decoration: none;
    text-transform: uppercase;
    border-width: $button-border-width-base;
    border-style: solid;
    box-sizing: content-box;
    user-select: none;

    // Primary Button Style
    background: $button-primary-bg-initial;
    color: $button-primary-color-initial;
    border-color: $button-primary-border-color-initial;

    &--sm {
        height: $button-height-small;
        line-height: $button-height-small;
        padding-inline: $button-side-padding-small;
        letter-spacing: $button-letter-spacing-small;
        font-size: $button-font-size-small;

        &#{$root} {
            &--link {
                font-size: $font-size-small;
            }
        }
    }

    &:hover,
    &.hover,
    &--hover {
        background-color: $button-primary-bg-hover;
        color: $button-primary-color-hover;
        border-color: $button-primary-border-color-hover;
        cursor: pointer;

        #{$root}__label {
            color: $button-primary-bg-hover;
        }
    }

    &--disabled,
    &.disabled,
    &:disabled {
        opacity: .3;
        pointer-events: none;
        cursor: not-allowed;
    }

    &--full {
        width: 100%;
    }

    &__label {
        display: inline-flex;
        align-items: center;
        height: inherit;
        position: relative;
        left: -$button-side-padding-base;
        border-bottom-left-radius: $button-border-radius-base;
        border-top-left-radius: $button-border-radius-base;
        background-color: color(white);
        color: $button-primary-bg-initial;
        transition: $button-transition-base;
        border-right: inherit;

        &-text {
            height: 100%;
            padding: 0 $button-label-side-padding-base;
        }

        .icon {
            margin: 0 $button-icon-spacing-base;
            color: currentColor;
        }
    }

    &__icon {
        display: flex;
        align-items: center;
        justify-content: center;
        color: currentColor;

        .icon {
            color: inherit;
            fill: currentColor;
        }
    }

    > img {
        display: inline-block;
        width: auto;
        max-height: 19px;
        margin-left: $button-icon-spacing-small;
    }

    &__text,
    &__icon {
        & + #{$root}__icon {
            margin-left: $button-icon-spacing-base;
            margin-right: math.div(-$button-icon-spacing-base, 1.5);
            color: currentColor;
        }

        & + #{$root}__label {
            border-bottom-left-radius: 0;
            border-top-left-radius: 0;
            border-bottom-right-radius: $button-border-radius-base;
            border-top-right-radius: $button-border-radius-base;
            right: -$button-side-padding-base;
            left: auto;
            border-right: none;
            border-left: inherit;

            .icon {
                color: currentColor;
            }
        }

        & + #{$root}__text {
            margin-left: $button-icon-spacing-base;
        }
    }

    &__text {
        pointer-events: none;
    }

    &--secondary {
        background-color: $button-secondary-bg-initial;
        color: $button-secondary-color-initial;
        border-color: $button-secondary-border-color-initial;

        #{$root}__label {
            color: $button-secondary-bg-initial;
        }

        &:hover,
        &.hover,
        &--hover {
            background-color: $button-secondary-bg-hover;
            color: $button-secondary-color-hover;
            border-color: $button-secondary-border-color-hover;

            #{$root}__label {
                color: $button-secondary-bg-hover;
            }
        }
    }

    &--ghost {
        background-color: $button-ghost-default-bg-initial;
        color: $button-ghost-default-color-initial;
        border-color: $button-ghost-default-border-color-initial;

        &:hover,
        &.hover,
        &--hover {
            background-color: $button-ghost-default-bg-hover;
            color: $button-ghost-default-color-hover;
            border-color: $button-ghost-default-border-color-hover;
        }
    }

    &--black {
        background-color: $button-ghost-black-bg-initial;
        color: $button-ghost-black-color-initial;
        border-color: $button-ghost-black-border-color-initial;

        &:hover,
        &.hover,
        &--hover {
            background-color: $button-ghost-black-bg-hover;
            color: $button-ghost-black-color-hover;
            border-color: $button-ghost-black-border-color-hover;

            &#{$root}--ghost {
                background-color: transparent;
                color: $button-primary-bg-initial;
                border-color: $button-primary-bg-initial;
            }
        }
    }

    &--white {
        background-color: $button-ghost-white-bg-initial;
        color: $button-ghost-white-color-initial;
        border-color: $button-ghost-white-border-color-initial;

        &:hover,
        &.hover,
        &--hover {
            background-color: $button-ghost-white-bg-hover;
            color: $button-ghost-white-color-hover;
            border-color: $button-ghost-white-border-color-hover;
        }
    }

    &--orange {
        background-color: $button-ghost-orange-bg-initial;
        color: $button-ghost-orange-color-initial;
        border-color: $button-ghost-orange-border-color-initial;

        &:hover,
        &.hover,
        &--hover {
            background-color: $button-ghost-orange-bg-hover;
            color: $button-ghost-orange-color-hover;
            border-color: $button-ghost-orange-border-color-hover;
        }
    }

    &--bordered {
        background: transparent;

        &#{$root}--white {
            color: color(white);

            &:hover,
            &.hover,
            &--hover {
                color: $button-ghost-white-color-initial;
                border-color: $button-ghost-white-color-hover;
                background: $button-ghost-white-color-hover;
            }
        }
    }

    &--link {
        line-height: initial;
        box-sizing: border-box;
        padding: 0;
        background-color: $button-link-bg-initial;
        color: $button-link-color-initial;
        border-color: $button-link-border-color-initial;

        #{$root}__label {
            left: 0;
            background-color: inherit;

            .icon {
                margin: 0;
            }
        }

        &#{$root}--orange {
            color: color(orange);

            &:hover,
            &--hover {
                color: color(orange, dark);
            }
        }

        &#{$root}--black {
            color: var(--tertiary-color);
        }

        &:hover,
        &.hover,
        &--hover {
            background-color: $button-link-bg-hover;
            color: $button-link-color-hover;
            border-color: $button-link-border-color-hover;
        }
    }
}

@media only screen and (max-width: $screen-sm) {
    .button {
        box-sizing: border-box;
        min-width: 160px;

        &--link {
            justify-content: flex-start;
        }
    }
}
