$header-height-base: 80px;
$header-initial-offset: 22px;
$header-container-logo-full-height-base: 200px;
$header-container-logo-full-width-base: 195px;
$header-container-logo-full-width-md: 171px;
$header-container-logo-full-height-md: 176px;
$header-container-logo-full-width-sm: 134px;
$header-container-logo-full-height-sm: 129px;
$header-padding-top: spacing(base);

:root {
    --header-height-base: #{$header-height-base};
    --header-offset-base: #{$header-initial-offset};
}

body.overlay-opened {
    .header {
        pointer-events: none;
    }
}

.header {
    $header: &;

    display: flex;
    width: 100%;
    height: var(--header-height-base);
    margin-top: var(--header-offset-base);
    position: relative;
    z-index: 21;

    &__logo {
        transition: opacity .2s ease-out;
        height: $header-container-logo-full-height-base;

        img, svg {
            width: auto;
            height: inherit;
        }

        &-container {
            position: absolute;
            top: 100%;
            left: calc(50% - (#{$header-container-logo-full-width-base} / 2));
            height: 100%;
            display: flex;
            align-items: center;
        }
    }

    &__actions {
        display: flex;
        align-items: center;
        height: 100%;
        margin-left: auto;
    }

    .navigation {
        height: auto;

        &__link {
            display: flex;
            align-items: center;
            height: 100%;

            &--active {
                position: relative;

                &:after {
                    content: '';
                    display: block;
                    width: 20%;
                    height: $border-width-fat;
                    background-color: var(--primary-color);
                    position: absolute;
                    bottom: 0;
                    left: 40%;
                }
            }

            &--active:has(.header__action) {
                .header__action {
                    @extend .button--hover;
                }
            }
        }
    }

    &__container {
        display: flex;
        align-items: center;
        width: 100%;
        max-width: container(1280);
        padding-inline: var(--side-padding-md);
        margin-inline: auto;

        #{$header}__content {
            display: flex;
            align-items: center;
            justify-content: space-between;
            position: relative;
            width: 100%;
            height: 100%;
        }
    }
}

body {
    .page {
        &:not(.home) {
            .header {
                &:before {
                    top: 0;
                    border-radius: 0;
                }
            }
        }
    }

    &.overlay-opened.js-navigation-overlay {
        .header {
            z-index: 14;

            &__container {
                box-shadow: none;
                background-color: transparent;
            }

            &__navigation {
                opacity: 0;
                pointer-events: none;
            }
        }
    }
}

@media only screen and (max-width: $screen-md) {
    :root {
        --header-height-base: 74px;
        --header-offset-base: 0px;
    }

    .header {
        $header: &;

        &__logo {
            width: $header-container-logo-full-width-md;
            height: $header-container-logo-full-height-md;

            &-container {
                left: calc(50% - (#{$header-container-logo-full-width-md} / 2));
            }
        }

        &__container {
            padding-inline: 0;

            #{$header}__navigation {
                .navigation__link a {
                    margin: 0 spacing(xs);
                }
            }
        }
    }
}

@media only screen and (max-width: $screen-sm) {
    .header {
        $header: &;

        --header-height-base: 64px;

        &__logo {
            width: $header-container-logo-full-width-sm;
            height: $header-container-logo-full-height-sm;

            &-container {
                left: calc(50% - (#{$header-container-logo-full-width-sm} / 2));
            }
        }

        &__action {
            display: none !important;
        }
    }
}
