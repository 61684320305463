@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Pacifico&family=Special+Elite&display=swap');

$fonts: (
    daddyRoys: (
        400: (
            name: 'Carena-Regular',
            weight: 400,
            style: normal
        )
    )
);

@each $font-family-key, $font-family-value in $fonts {
    @each $family-item-key, $family-item-value in $font-family-value {
        @font-face {
            $get-font-name: map-get($family-item-value, name);

            font-family: #{$font-family-key};
            src: url(#{$fonts-folder}#{$get-font-name}.woff2) format('woff2'),
            url(#{$fonts-folder}#{$get-font-name}.woff) format('woff'),
            url(#{$fonts-folder}#{$get-font-name}.ttf) format('truetype');
            font-weight: map-get($family-item-value, weight);
            font-style: map-get($family-item-value, style);
        }
    }
}

// Family
$font-family-primary: 'Lato', sans-serif;
$font-family-secondary: 'Pacifico', cursive;
$font-family-epic: 'daddyRoys', serif;
$font-family-typewriter: 'Special Elite', system-ui;

// Weight
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-semi-bold: 600;
$font-weight-bold: 700;
$font-weight-extra-bold: 800;
$font-weight-black: 900;
$font-style-italic: italic;

// Spacing
$letter-spacing-extra-small: 2.16px;
$letter-spacing-extra-small-neg: -2.16px;
$letter-spacing-small: .4px;
$letter-spacing-small-neg: -.4px;
$letter-spacing-base: 0.25px;
$letter-spacing-base-neg: -.25px;
$letter-spacing-medium: 0.56px;
$letter-spacing-medium-neg: -.56px;
$letter-spacing-heavy: 1px;
$letter-spacing-heavy-neg: -1px;

// Sizes
$font-size-tiny-px: 10px;
$font-size-extra-small-px: 12px;
$font-size-small-px: 14px;
$font-size-base-px: 16px;
$font-size-medium-px: 20px;
$font-size-heavy-px: 24px;
$font-size-large-px: 26px;
$font-size-extra-large-px: 32px;
$font-size-huge-px: 40px;
$font-size-extra-huge-px: 48px;
$font-size-giant-px: 92px;
$font-size-epic-px: 108px;

$font-size-tiny: calculateRem($font-size-base-px, $font-size-tiny-px);
$font-size-extra-small: calculateRem($font-size-base-px, $font-size-extra-small-px);
$font-size-small: calculateRem($font-size-base-px, $font-size-small-px);
$font-size-base: calculateRem($font-size-base-px, $font-size-base-px);
$font-size-medium: calculateRem($font-size-base-px, $font-size-medium-px);
$font-size-heavy: calculateRem($font-size-base-px, $font-size-heavy-px);
$font-size-large: calculateRem($font-size-base-px, $font-size-large-px);
$font-size-extra-large: calculateRem($font-size-base-px, $font-size-extra-large-px);
$font-size-huge: calculateRem($font-size-base-px, $font-size-huge-px);
$font-size-extra-huge: calculateRem($font-size-base-px, $font-size-extra-huge-px);
$font-size-giant: calculateRem($font-size-base-px, $font-size-giant-px);
$font-size-epic: calculateRem($font-size-base-px, $font-size-epic-px);

// Line Height
$line-height-extra-small: 95%;
$line-height-small: 120%;
$line-height-base: 1.6em;
$line-height-medium: 1.688em;
$line-height-large: 1.74em;
$line-height-huge: 1.714em;

// Color
$font-color-base: color(black);

:root {
    --font-size-tiny: #{$font-size-tiny};
    --font-size-base: #{$font-size-base};
    --font-size-extra-small: #{$font-size-extra-small};
    --font-size-small: #{$font-size-small};
    --font-size-medium: #{$font-size-medium};
    --font-size-heavy: #{$font-size-heavy};
    --font-size-large: #{$font-size-large};
    --font-size-extra-large: #{$font-size-extra-large};
    --font-size-huge: #{$font-size-huge};
    --font-size-extra-huge: #{$font-size-extra-huge};
    --font-size-giant: #{$font-size-giant};
    --font-size-epic: #{$font-size-epic};
}

.font {
    &--base {
        font-size: var(--font-size-base);
        line-height: $line-height-base;
    }

    &--small {
        font-size: var(--font-size-small);
        line-height: $line-height-base;
    }

    &--medium {
        font-size: var(--font-size-medium);
        line-height: $line-height-medium;
    }

    &--large {
        font-size: var(--font-size-large);
        line-height: $line-height-medium;
    }

    &--regular {
        font-weight: $font-weight-regular;
    }

    &--bold {
        font-weight: $font-weight-bold;
    }
}

body {
    font-family: $font-family-primary;
    font-weight: $font-weight-medium;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

html, body {
    font-size: $font-size-base-px;
    line-height: $line-height-base;
}

@media only screen and (max-width: $screen-md) {
    html, body {
        font-size: $font-size-base-px;
    }
}

@media only screen and (max-width: $screen-sm) {
    html, body {
        font-size: $font-size-base-px;
    }
}

