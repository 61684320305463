.content {
    width: 100%;
    min-height: 100%;
    margin: 0 auto;
    position: relative;
    background-color: color(sand, 25);

    &--max-width {
        max-width: map-get($breakpoints, hg);
    }
}

@media only screen and (max-width: map-get($breakpoints, lg)) {
    .content {
        padding-top: 0 !important;
        padding-bottom: spacing(hg);
    }
}
