.contact {
    &__hero {
        margin-bottom: spacing(xl);
    }

    &__info {
        display: flex;
        gap: spacing(gt);

        &-item {
            display: flex;
            flex-direction: column;
            text-align: center;
            gap: spacing(base);

            .heading {
                font-family: $font-family-epic;
                font-size: $font-size-extra-huge;
                font-weight: initial;
                margin-bottom: 0;
            }

            a {
                font-weight: $font-weight-black;
            }
        }
    }
}

@media only screen and (max-width: map-get($breakpoints, md)) {
    .contact {
        &__hero {
            img {
                max-height: 200px;
            }
        }

        &__info {
            flex-direction: column;
            gap: spacing(xl);

            &-item {
                gap: spacing(xs);

                .heading {
                    font-size: $font-size-huge;
                }
            }
        }
    }
}
