.box {
    &__content {
        padding: spacing(hg);

        p {
            margin-bottom: 0;
        }
    }

    &--grey {
        background-color: color(grey, 100);
        color: color(black);

        .heading {
            color: color(black);
        }
    }

    &--blue {
        background-color: color(orange);
        color: color(white);
        border: none;

        .heading {
            color: color(white);
        }
    }

    &--bordered {
        border: $border-width-thin solid color(grey, 300);
    }

    &--rounded {
        border-radius: $border-radius-extra-large;
    }

    &--spaced {
        padding: spacing(hg);
    }
}

@media only screen and (max-width: map-get($breakpoints, sm)) {
    .box {
        &--spaced {
            padding: spacing(md);
        }

        &__content {
            //padding: spacing(lg)
        }
    }
}
