@use 'sass:math';

.details {
    &__section {
        &--bordered {
            padding-bottom: spacing(lg);
            margin-bottom: 0;
            border-bottom: $border-width-base solid color(sand, 100);
        }

        ul {
            @extend .list--disc;
        }

        &--top {
            position: relative;
            z-index: 12;
            background-color: inherit;
        }
    }

    &__gallery {
        grid-gap: 20px;
        display: grid;
        grid-template-columns: 1fr .5fr .5fr;
        position: relative;
        width: 100%;
        padding-top: spacing(base);
        padding-inline: calc(var(--side-padding) + #{spacing(md)});
        z-index: 1;

        &-item {
            position: relative;
            display: block;
            margin: 0;
            padding: 0;
            border-radius: $border-radius-extra-large;
            overflow: hidden;

            &.hide {
                display: none !important;
            }

            &:first-child {
                grid-row: span 2;
            }

            img {
                height: 100%;
                left: 0;
                object-fit: cover;
                position: absolute;
                top: 0;
                width: 100%;
            }

            a {
                display: block;
                height: 100%;
                overflow: hidden;
                padding-bottom: 100%;
                position: relative;
                width: 100%;
            }

            figcaption {
                display: none;
            }
        }
    }

    &__reviews {
        display: flex;
        align-items: flex-start;
        gap: spacing(lg);
        max-width: 100%;
        overflow: scroll;
        padding-inline: var(--side-padding);
        padding-bottom: spacing(lg);

        .review {
            min-width: var(--review-width);

            &__content {
                min-width: inherit;
            }
        }
    }

    &__map {
        height: 250px;

        iframe {
            width: 100%;
            height: 100%;
            border-radius: $border-radius-large;
        }
    }

    &__amenities {
        gap: spacing(base);

        &-item {
            display: flex;
            align-items: center;
            gap: spacing(xs);

            &:before {
                display: none !important;
            }
        }
    }

    &__rooms {
        display: flex;
        gap: spacing(md);

        &-item {
            display: inline-flex;
            width: auto;
            flex-direction: column;
            padding: spacing(base) spacing(md);
            border: $border-width-base solid color(sand, 100);
            border-radius: $border-radius-large;
            gap: 0 !important;

            &:before {
                display: none !important;
            }
        }

        &-name {
            margin-bottom: math.div(spacing(tn), 2);
        }

        &-desc {
            margin-bottom: spacing(xs);
        }

        &-icons {
            display: flex;
            gap: spacing(tn);
        }
    }

    &__footer {
        @extend .listings__navigation;
        background-color: var(--background-color);
        flex-direction: column;
        padding-block: spacing(lg) 0;
        bottom: 0;
        margin-top: 0;

        p {
            margin-bottom: 0;
        }

        &:before {
            content: '';
            width: 100%;
            @extend .container--1000;
            display: block;
            border-top: $border-width-fat solid color(brown);
            margin: - spacing(lg) auto spacing(lg) auto;
        }

        &-header {
            display: flex;
            justify-content: center;
            gap: spacing(xs);
            margin-bottom: spacing(sm);

            > * {
                margin-bottom: 0;
            }
        }

        &-heading {
            width: auto;
            font-family: $font-family-epic;
            font-weight: initial;
        }

        &-location {
            font-size: $font-size-medium;
        }

        .caption {
             font-weight: $font-weight-bold;
             font-size: $font-size-extra-small;

             &--spaced {
                 padding-inline: spacing(base);
             }
         }

        &-actions {
            display: flex;
            justify-content: center;
            align-items: center;
            padding-bottom: spacing(base);

            &-buttons {
                display: flex;
                align-items: flex-start;
                gap: spacing(sm);

                .button {
                    min-width: 100px;
                }
            }

            p a {
                font-weight: $font-weight-bold;
                text-decoration: underline;
                color: color(brown);
            }
        }
    }
}

@media only screen and (max-width: map-get($breakpoints, xl)) {
    .details {
        &__footer {
            display: flex !important;

            &-actions {
                align-items: center !important;
                justify-content: center;
            }
        }
    }
}

@media only screen and (max-width: map-get($breakpoints, md)) {
    .details {
        &__footer {
            &-header {
                margin-bottom: spacing(xs);
            }
            &-actions {
                flex-direction: column;
                align-items: flex-start;

                p, .caption {
                    margin-bottom: spacing(tn);
                }

                &-buttons {
                    align-items: center;
                    flex-direction: row;
                    flex-wrap: wrap;
                    justify-content: center;
                    width: 100%;

                    .button {
                        width: auto;
                        max-width: 50%;
                    }
                }
            }
        }

        &__amenities {
            width: 250px !important;
            flex-basis: 250px !important;
            padding-top: spacing(base);
            padding-inline: 0;

            &-grid {
                overflow: scroll;
            }

            &-container {
                width: fit-content;
                flex-direction: row;
                padding-bottom: spacing(base);
            }
        }

        &__rooms {
            width: fit-content;

            &-container {
                width: 100%;
                overflow: scroll;
                padding-bottom: spacing(tn);
            }

            &-item {
                white-space: nowrap;
            }
        }
    }
}

@media only screen and (max-width: map-get($breakpoints, sm)) {
    .details {
        &__footer {
            position: static;
            text-align: center;
            padding-bottom: spacing(lg);

            &:after {
                display: none;
            }

            &-header {
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 0;
                margin-bottom: spacing(xs);
            }
            &-heading {
                font-size: $font-size-large;
                margin-bottom: 0;
            }
        }
        &__reviews {
            padding-inline: spacing(md);
        }
        &__gallery {
            padding-inline: spacing(base);
            grid-template-rows: 1fr 1fr 1fr;
            grid-template-columns: 1fr 1fr;
            gap: 12px;

            &-item {
                &:first-child {
                    grid-row: initial;
                    grid-area: 1 / 1 / 3 / 3;
                }

                &:nth-child(2n) {
                    grid-area: 3 / 1 / 4 / 2;
                }

                &:nth-child(3n) {
                    grid-area: 3 / 2 / 4 / 3;
                }

                &:nth-child(4n) {
                    display: none;
                    grid-area: 4 / 1 / 5 / 2;
                }

                &:nth-child(5n) {
                    display: none;
                    grid-area: 4 / 2 / 5 / 3;
                }
            }
        }
    }
}
