:root {
    --jump-nav-height: 60px;
}

.jump-nav {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: spacing(hg);
    width: 100%;
    height: var(--jump-nav-height);
    position: -webkit-sticky;
    position: sticky;
    top: var(--header-height-base);
    background-color: var(--background-color);
    border-bottom: $border-width-base solid color(grey, 200);
    z-index: 9;
    box-shadow: 0 16px 56px 0 rgba(0,0,0,.05);

    &__item {
        text-transform: uppercase;
        font-weight: $font-weight-semi-bold;
        letter-spacing: $letter-spacing-medium;
        font-size: $font-size-small;
        color: var(--tertiary-color);

        &.active {
            color: var(--primary-color);
        }
    }
}

@media only screen and (max-width: map-get($breakpoints, md)) {
    .jump-nav {
        --header-height-base: 64px;
    }
}
@media only screen and (max-width: map-get($breakpoints, sm)) {
    .jump-nav {
        --header-height-base: 64px;
        --jump-nav-height: 44px;

        gap: spacing(base);
        padding-inline: var(--side-padding-sm);

        &__item {
            font-size: $font-size-extra-small;
            letter-spacing: $letter-spacing-small;
            white-space: nowrap;
        }
    }
}
