@use "sass:math";

$list-disc-size: 5px;
$list-box-size: 45px;

.list {
    &--disc {
        li {
            display: flex;
            gap: spacing(xs);
            align-items: flex-start;
            margin-bottom: spacing(tn);

            &:before {
                content: '';
                display: block;
                width: $list-disc-size;
                min-width: $list-disc-size;
                height: $list-disc-size;
                min-height: $list-disc-size;
                border-radius: $list-disc-size;
                background-color: currentColor;
                margin-top: spacing(xs);
                position: relative;
                top: -1px;
            }
        }
    }

    &--comma {
        li {
            &:after {
                content: ',';
            }

            &:last-child {
                &:after {
                    display: none;
                }
            }
        }
    }

    &--box {
        li {
            display: flex;
            align-items: flex-start;
            font-family: $font-family-primary;
            font-size: $font-size-small;
            line-height: $line-height-base;
            margin-bottom: spacing(md);

            &:before {
                display: inline-flex;
                align-items: center;
                justify-content: center;
                width: $list-box-size;
                min-width: $list-box-size;
                height: $list-box-size;
                min-height: $list-box-size;
                background: color(orange);
                color: color(white);
                font-family: $font-family-primary;
                font-weight: $font-weight-bold;
                margin-right: spacing(base);
            }
        }
    }
}

ol {
    &.list {
        counter-reset: ol-counter;

        li {
            counter-increment: ol-counter;

            &:before {
                content: counter(ol-counter);
                line-height: 1;
            }
        }
    }
}

@media only screen and (max-width: map-get($breakpoints, sm)) {
    .list {
        &--box {
            li {
                align-items: flex-start;
            }
        }
    }
}
