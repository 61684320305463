.direction
{
    &--column
    {
        flex-direction: column;
    }

    &--row
    {
        flex-direction: row;
    }
}
