body {
    display: flex;
}

.body {
    &--fixed {
        position: fixed;
        width: 100%;
    }

    &--loading {
        overflow: hidden;
    }
}
