.footer {
    $root: &;

    width: 100%;
    padding-bottom: spacing(hg);
    background: color(white);
    color: color(grey, 800);
    margin-top: auto;
    position: relative;
    z-index: 4;

    &__content {
        padding: spacing(gt) 0 spacing(md);
    }

    &__links {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: spacing(base);

        > .footer__link {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: spacing(base);
            position: relative;

            > a {
                padding: 0;
            }

            &:after {
                content: '';
                display: flex;
                width: $border-width-base;
                height: spacing(sm);
                background-color: color(grey, 100);
            }

            &:last-child {
                &:after {
                    display: none;
                }
            }
        }
    }

    &__link {
        display: flex;

        > a {
            color: inherit;
            font-size: $font-size-small;
            font-weight: $font-weight-medium;
            padding: 0 spacing(sm);
        }
    }

    &__copy {
        display: block;
        width: 100%;
        color: currentColor;
        text-align: center;
        font-size: $font-size-small;
        font-weight: $font-weight-semi-bold;

        a {
            color: inherit;
        }
    }

    .navigation {
        color: color(grey, 800);

        &__link {
            font-weight: $font-weight-medium;

            a {
                padding: 0;
                margin: 0;
            }
        }
    }
}

@media only screen and (max-width: map-get($breakpoints, md)) {
    .footer {
        padding: spacing(xl) spacing(hg);

        &__content {
            padding-top: spacing(lg);
        }

        .navigation {
            &__group {
                flex-basis: 50%;

                &--contact {
                    flex-basis: 100%;
                    border-top: $border-width-base solid color(grey, 100);
                    border-bottom: $border-width-base solid color(grey, 100);
                    padding-block: spacing(lg);
                    margin-top: spacing(lg);
                    text-align: center;
                    align-items: center;

                    .button {
                        justify-content: center;
                        width: 100%;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: map-get($breakpoints, sm)) {
    .footer {
        padding: spacing(xl) spacing(md);

        &__content {
            padding: 0;
        }

        &__links {
            gap: spacing(xs);

            > .footer__link {
                gap: spacing(xs);
            }
        }
    }
}


@media only screen and (orientation: landscape) {
    .footer {
        $root: &;
    }
}
