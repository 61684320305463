/* Styling the columns block */
.wp-block-columns {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: spacing(base);
}

.wp-block-column {
    flex-grow: 1;
    min-width: 0;
    word-break: break-word;
    overflow-wrap: break-word;
}

@media only screen and (min-width: map-get($breakpoints, sm)) {
    .wp-block-columns {
        flex-wrap: nowrap;
    }

    .wp-block-columns .wp-block-column {
        flex-basis: 0;
        flex-grow: 1;

        &:not(:first-child) {
            margin-left: spacing(base) * 2;
        }
    }
}

.wpcf7-list-item {
    label:has(input[type=checkbox]) {
        position: relative;

        input[type=checkbox] {
            @extend .checkbox__input;
            //position: static !important;
            //width: 20px !important;
            //height: 20px !important;
            //clip-path: initial !important;
        }

        .wpcf7-list-item-label {
            @extend .checkbox__label;

            &:after {
                display: none !important;
            }

            &:before {
                content: '';
                display: inline-flex;
                align-items: center;
                justify-content: center;
                width: $checkbox-size;
                min-width: $checkbox-size;
                height: $checkbox-size;
                min-height: $checkbox-size;
                line-height: $checkbox-size - ($checkbox-border-width * 4);
                background-color: $checkbox-bg-color-base;
                vertical-align: bottom;
                border-radius: $checkbox-border-radius;
                border: $checkbox-border-width solid $checkbox-border-color-base;
                text-align: center;
                color: $checkbox-color-base;
                margin-right: spacing(xs);
                position: relative;
                top: -3px;
            }
        }

        &:before {
            content: '';
            @extend .icon;
            @extend .icon-mask;
            @extend .icon-Tick;
            position: absolute !important;
            left: 2px;
            top: 7px !important;
            z-index: 1;
            color: transparent;
        }

        &:hover {
            &:before {
                color: $checkbox-color-hover;

            }

            .wpcf7-list-item-label {
                &:before {
                    border-color: $checkbox-border-color-hover;
                }
            }
        }
    }

    label:has(input[type=checkbox]:checked) {
        .wpcf7-list-item-label {
            &:before {
                background-color: $checkbox-bg-color-checked;
                border-color: $checkbox-border-color-checked;
                color: $checkbox-color-checked;
            }
        }

        &:before {
            display: block !important;
            color: white;
        }
    }
}
