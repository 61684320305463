$navigation-link-height-base: 44px;
$navigation-link-height-small: 32px;
$dropdown-arrow-size: 12px;
$dropdown-bg-color: color(white);
$dropdown-transition: all 250ms ease-out;

:root {
    --navigation-height-base: #{$navigation-link-height-base};
}


.navigation {
    $root: &;

    display: flex;
    flex-wrap: wrap;
    color: color(brown);

    &__group {
        display: flex;
        flex-direction: column;
        flex: 1;

        #{$root}__link {
            > a:not(.button) {
                display: flex;
                width: 100%;
                justify-content: flex-start;
                height: $navigation-link-height-small;
                padding: 0;
                margin: 0;

                .icon {
                    margin-right: spacing(xs);
                }
            }
        }
    }

    &__link {
        > a {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            height: $navigation-link-height-base;
            padding: 0 spacing(sm);
            margin: 0 spacing(sm);
            color: currentColor;
            font-size: $font-size-base;
            font-weight: $font-weight-bold;
            text-decoration: none;
            transition: $transition-base;

            .icon {
                color: currentColor;
            }
        }

        &--active {
            > a {
                color: color(orange);
            }
        }

        &:not(&--active) {
            &:hover {
                > a {
                    color: var(--color-accent-primary);
                }
            }
        }

        &--md {
            > a {
                font-size: $font-size-base;
            }
        }
    }

    &__button {
        margin-left: spacing(sm);

        > a {
            color: inherit;
            background: inherit;
        }

        &.navigation__link--active {
            background-color: color(orange);
            color: color(white);
        }
    }

    &--vertical {
        flex-direction: column;

        #{$root}__link {
            > a {
                justify-content: flex-start;
                padding: 0;
            }
        }
    }

    &--2-cols {
        flex-direction: row;

        #{$root}__link {
            width: 50%;
            flex-basis: 50%;
            padding-left: spacing(xs);

            &:nth-child(odd) {
                padding-left: 0;
                padding-right: spacing(xs);
            }
        }
    }

    &--secondary {
        #{$root}__link {
            > a {
                font-family: $font-family-primary;
                font-size: $font-size-medium;
                padding: 0;
                margin-right: spacing(xl);
            }
        }
    }
}
