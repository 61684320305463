.wp-block-button__link {
    @extend .button;
    @extend .button--secondary;
}

.button--with-icon .wp-block-button__link {
    &:after {
        content: '';
        display: inline-flex;
        align-items: center;
        justify-content: center;
        fill: currentColor;
        stroke: none;
        vertical-align: middle;
        position: relative;

        background-color: currentColor;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;

        mask-image: url(#{$sprite}#ArrowRight-view);
        -webkit-mask-image: url(#{$sprite}#ArrowRight-view);

        color: inherit;
        width: iconSize(sm);
        height: iconSize(sm);
        margin-left: $button-icon-spacing-base;
    }
}

body {
    .wpcf7-not-valid-tip {
        transition: 0.28s;
        overflow: hidden;
        color: color(system, red);
        font-size: var(--font-size-small);
        font-style: italic;
        font-weight: $font-weight-medium;
        padding-top: spacing(xs);
    }
}

.form__row:has(.wpcf7-validates-as-required) {
    .form__label {
        &:after {
            content: "*";
            margin: -4px 0 0 4px;
            color: color(system, red);
        }
    }
}
.form__row:has(.wpcf7-form-control-wrap):has(.form__input.wpcf7-not-valid),
.form__row:has(.wpcf7-form-control-wrap):has(.form__input:invalid) {
    .form__input {
        box-shadow: 0 0 0 $form-input-border-width-focus $form-input-border-color-error inset;
        background-color: rgba($form-input-border-color-error, .05);
    }

    .form__input-requirements {
        max-height: 100px;
        padding: spacing(xs) 0 0 0;
    }
}
