.color {
    @each $color-key, $color-value in $colors
    {
        @each $tone-key, $tone-value in $color-value
        {
            @if $tone-key == base
            {
                &#{'--' + $color-key}
                {
                    color: $tone-value !important;
                }
            } @else
            {
                &--#{$tone-key + '-' + $color-key}
                {
                    color: $tone-value !important;
                }
            }
        }
    }

    &--primary {
        color: var(--primary-color);
    }

    &--secondary {
        color: var(--secondary-color);
    }
}

.fill {
    @each $color-key, $color-value in $colors
    {
        @each $tone-key, $tone-value in $color-value
        {
            @if $tone-key == base
            {
                &#{'--' + $color-key}
                {
                    background-color: $tone-value !important;
                }
            } @else
            {
                &--#{$tone-key + '-' + $color-key}
                {
                    background-color: $tone-value !important;
                }
            }
        }
    }

    &--primary {
        background-color: var(--primary-color);
    }

    &--secondary {
        background-color: var(--secondary-color);
    }
}
