$form-select-height: $form-input-height-base;
$form-select-side-padding: $form-input-horizontal-padding;
$form-select-font-size: $form-input-font-size;

$form-select-border-radius: $form-input-border-radius;
$form-select-border-width: $form-input-border-width;
$form-select-border-color-base: $form-input-border-color-base;
$form-select-font-color-base: $form-input-font-color-base;
$form-select-bg-color-base: $form-input-bg-color-base;

.form {
    &__select
    {
        @extend .form__input;

        background-image: url(#{$sprite}#ChevronUpDown-view);
        background-repeat: no-repeat;
        background-position: calc(100% - 12px) center;
        background-size: iconSize(sm) iconSize(sm);

        &::placeholder {
            color: $form-input-placeholder-color-base;
        }

        &--disabled
        {
            cursor: not-allowed;
            background-color: $form-input-bg-color-disabled;
        }
    }
}
