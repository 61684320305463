$form-input-height: 64px;
$form-input-bg-color: color(grey, 50);
$form-input-border-color-default: $form-input-bg-color;
$form-input-border-color-active: color(orange);
$form-input-border-width: $border-width-base;
$form-input-border-radius: 0;
$form-input-color: color(orange);
$form-input-placeholder-color: rgba($form-input-color, .6);

input[type=checkbox],
input[type=radio] {
    border: none;
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
    white-space: nowrap;
}

.form {
    $root: &;

    display: flex;
    flex-flow: column;
    justify-content: center;
    width: 100%;

    &__fieldset {
        margin-bottom: 30px;
    }

    &__row {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        position: relative;
        align-items: flex-end;
        margin-bottom: spacing(xs);
    }

    &__column {
        display: flex;
        flex-direction: column;
        flex: 1;
    }

    .button {
        &--copy-url {
            display: flex;
            position: absolute;
            background-color: transparent;
            right: 0;
            justify-content: flex-end;

            .icon {
                color: color(orange);
            }
        }
    }

    &__footer {
        display: flex;
        justify-content: flex-end;
        padding: spacing(base) 0;
        margin-top: spacing(base);
        border-top: $border-width-base solid color(grey, 200);
    }

    &__submit {
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: $form-input-height;
        width: $form-input-height;
        height: $form-input-height;
        background-color: color(orange);
        color: color(white);
        cursor: pointer;

        &:hover, &:active {
            background-color: color(orange);
        }
    }
}

@media only screen and (max-width: $screen-sm)
{
    .form {
        &__footer {
            justify-content: center;
            .button {
                //width: 100%;
            }
        }
    }
}
